import axios from "axios";
import {NewsModel} from "../model/newsModel";
import NotificationModel from "../model/notificationModel";
import TickerModel from "../model/tickerModel";
import OddsModel from "../model/oddsModel";
import StateService from "./stateService";
import {StateConfig} from "../config/config";

export default class ApiService {
    static _instance;

    constructor() {
        this.apiBase = "http://localhost:8001";
        // this.apiBase = "http://localhost:3001";
        this.oddsApiBase = "http://localhost:5001";
        this.routes = {
            ACCOUNT_REGISTER: '/api/v1/user/register',
            ACCOUNT_LOGIN: '/api/v1/user/login',
            ACCOUNT_AUTHENTICATE: '/api/v1/user/authenticate',
        };
        this.headersConfig = {
            "Content-type": "application/json",
            "Accept": "application/json",
            'Access-Control-Allow-Origin': '*'
        }
    }

    static instance() {
        if(this._instance) return this._instance;

        this._instance = new ApiService();
        return this._instance;
    }

    async login(loginRequestModel) {
        //Todo: create api call for logging in
        return await this.POST(this.routes.ACCOUNT_LOGIN, loginRequestModel);
    }

    async validateSession(userId, sessionId) {
        const sessionRequest = {'userId': userId, 'sessionId': sessionId}
        return await this.POST(this.routes.ACCOUNT_AUTHENTICATE, sessionRequest)
    }

    async logout() {
        //Todo: create api call for logging out

        StateService.instance().clearState();
    }

    async register(registerRequestModel) {
        //Todo: create api call for registering
        return await this.POST(this.routes.ACCOUNT_REGISTER, registerRequestModel);
    }
    getNews(newsRequestModel) {
        const results = Array.from({length: 50}, () => {
            return new NewsModel('test', 'http://google.com', ['ufc', 'nba', 'nfl'])
        });

        return results;

    }
    getNotifications(notificationsRequestModel) {
        const results = Array.from({length: 25}, () => {
            return new NotificationModel('test', 'Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum ',
                '', Date.now())
        });

        return results;
    }
    getTickers(tickerRequestModel) {
        const results = this.scores.map(x => TickerModel.fromResult(x)).filter(x => x !== null);
        return [...results, ...results];
    }

    getOdds(oddsRequestModel) {
        const results = [this.odds, this.odds2].flat();
        return OddsModel.fromApi(results);
    }

    async updateUserWorksheets(userId, worksheets) {
        const results = await this.POST('/api/v1/user/worksheet', {userId: userId, worksheets: worksheets});
        return results;
    }

    async getUserWorksheets(userId) {
        const results = await this.GET(`/api/v1/user/worksheet/${userId}`);
        return results;
    }

    async getUserSettings(userId) {
        const results = await this.GET(`/api/v1/user/settings/${userId}`);
        return results
    }

    async getStats(statsRequest) {
        return await this.POST('/api/v1/sports/stats', statsRequest);
    }

    async getAdditionalTeamStats(statsRequest) {
        return await this.POST('/api/v1/sports/stats/extras', statsRequest);
    }

    async getPlayers(statsRequest) {
        return await this.POST('/api/v1/sports/players', statsRequest);
    }

    async getTeams(teamsRequest) {
        return await this.POST('/api/v1/sports/teams', teamsRequest);
    }

    saveWorksheet(saveWorksheetModel) {
        //Todo: create api call for saving worksheet
    }

    async GET(endpoint, altBaseUrl = null) {
        const headers = this.headersConfig;
        return axios({
            method: 'get',
            url: `${altBaseUrl == null ? this.apiBase : altBaseUrl}${endpoint}`,
            headers: headers
        });
    }

    async POST(endpoint, data, altBaseUrl = null) {
        const headers = this.headersConfig;
        return axios({ method: 'post',
            url: `${altBaseUrl == null ? this.apiBase : altBaseUrl}${endpoint}`,
            data: data,
            headers: headers
        });
    }
}