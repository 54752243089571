import {isNull, uniq} from "lodash";
import {BetConfig} from "../config/config";

class MarketModel {
    marketType;
    team1;
    team2;
    team1Price;
    team2Price;
    isSpread;
    team1Spread;
    team2Spread;
    isProp;
    player;
    propType;
    bookmakerName;
    overPrice;
    underPrice;
    overPoint;
    underPoint;
    propPrice;

}
class OddsModel {

    eventId;
    team1;
    team2;
    markets;

    getPropMarkets() {
        return this.markets.filter(x => x.marketType !== 'h2h' || x.marketType !== 'spreads' );
    }
    getSpreadMarkets() {
        return this.markets.filter(x => x.marketType === 'spreads' );
    }
    getMoneyLineMarkets() {
        return this.markets.filter(x => x.marketType === 'h2h' );
    }
    getBestMoneyLineMarket() {
        const mlMarket = this.getMoneyLineMarkets()[0] || new MarketModel();
        return mlMarket;
    }
    getBestSpreadMarket() {
        const spreadMarket = this.getSpreadMarkets()[0] || new MarketModel();
        return spreadMarket;
    }
    constructor() {

    }

    static fromApi(data) {
        const results = data.map(apiData => {
            const eventId = apiData['id']
            const team1 = apiData['home_team'];
            const team2 = apiData['away_team'];
            const oddsModel = new OddsModel();
            if(!isNull(apiData['bookmakers'])) {
                // oddsModel.title = bookmaker.title;
                oddsModel.team1 = team1;
                oddsModel.team2 = team2;
                oddsModel.eventId = eventId;
                oddsModel.eventId = eventId;
                const allMarkets = apiData['bookmakers'].map(bookmaker => {
                    const markets = bookmaker.markets.map(market => {
                        const marketModel = new MarketModel();
                        const bookmakerName = marketModel.bookmakerName = bookmaker.title;
                        marketModel.marketType = market.key;
                        if(market.key === BetConfig.marketType.h2h) {
                            marketModel.team1 = market.outcomes.find(outcome => outcome.name == team1).name || null;
                            marketModel.team2 = market.outcomes.find(outcome => outcome.name == team2).name || null;
                            marketModel.team1Price = market.outcomes.find(outcome => outcome.name == team2).price || null;
                            marketModel.team2Price = market.outcomes.find(outcome => outcome.name == team1).price || null;
                        } else if(market.key === BetConfig.marketType.spreads) {
                            marketModel.isSpread = true;
                            marketModel.team1 = market.outcomes.find(outcome => outcome.name == team1).name || null;
                            marketModel.team2 = market.outcomes.find(outcome => outcome.name == team2).name || null;
                            marketModel.team1Price = market.outcomes.find(outcome => outcome.name == team2).price || null;
                            marketModel.team2Price = market.outcomes.find(outcome => outcome.name == team1).price || null;
                            marketModel.team1Spread = market.outcomes.find(outcome => outcome.name == team2).spread || null;
                            marketModel.team2Spread = market.outcomes.find(outcome => outcome.name == team1).spread || null;

                        } else {
                            const descriptions = uniq(market.outcomes.map(x => x.description));
                            const marketModels = descriptions.map(x => {
                                const matchingOutComes = market.outcomes.filter(outcome => outcome.description == x);
                                const mm = new MarketModel();
                                mm.bookmakerName = bookmakerName;
                                mm.isProp = true;
                                mm.propType = market.key;
                                mm.propPlayer = x;
                                matchingOutComes
                                    .forEach(outcome => {
                                        if(outcome.name.toLowerCase() == "over")
                                            mm.overPrice = outcome.price;
                                            mm.overPoint = outcome.point;
                                        if(outcome.name.toLowerCase() == "under")
                                            mm.underPrice = outcome.price;
                                            mm.underPoint = outcome.point;
                                    });
                                return mm;
                            });
                            return marketModels;
                        }
                        return marketModel;
                    });
                    return markets;
                });
                oddsModel.markets = allMarkets.flat();
                return oddsModel;
            }
            return null;
        });

        return results.filter(x => x !== null).flat();
    }
}

export default OddsModel;