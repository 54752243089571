import SideNav from "../sideNav";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import WorksheetService from "../../service/worksheetService";
import StateService from "../../service/stateService";
import {StateConfig} from "../../config/config";
import ApiService from "../../service/apiService";
import styles from './banner.module.css'
// import {useNavigate} from "react-router-dom";

export default function Banner() {
    const stateService = StateService.instance();
    // const navigate = useNavigate();
    // const [currentAnimation, setCurrentAnimation] = useState('');
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const worksheetService = WorksheetService.instance();
    useEffect(() => {
        // const updateIsLoggedIn = (newState) => {
        //     setIsLoggedIn(newState.isLoggedIn);
        // }
        //
        // stateService.registerListener(StateConfig.IS_LOGGED_IN, updateIsLoggedIn);
        // if(StateService.instance().getSharedState().isLoggedIn) {
        //     const rehydratedState = StateService.instance().rehydrate([
        //         StateConfig.IS_LOGGED_IN
        //     ]);
        //     setIsLoggedIn(rehydratedState[StateConfig.IS_LOGGED_IN]);
        // }
    }, [])
    const toggleSubMenu = () => {
        // setSubMenuOpen(!isSubMenuOpen)
    };

    const openModal = () => {
        stateService.updateState('isModalOpen', true);
    }

    const logout = () => {
        ApiService.instance().logout();
        window.location.reload();
    }

    const displayBannerListItems = () => {
        const bannerListItems = [];
        if(isLoggedIn) {
            bannerListItems.push((<li className={styles.bannerSubMenuListItem}><a onClick={openModal}>Create Worksheet</a></li>));
            bannerListItems.push((<li className={styles.bannerSubMenuListItem}><a href={'/account'}>Account</a></li>));
            bannerListItems.push((<li className={styles.bannerSubMenuListItem}><a href={'/'} onClick={() => logout()}>Logout</a></li>));
        } else {
            bannerListItems.push((<li className={styles.bannerSubMenuListItem}><a href={'/login'}>Login</a></li>));
            bannerListItems.push((<li className={styles.bannerSubMenuListItem}><a href={'/register'}>Register</a></li>));
            bannerListItems.push((<li className={styles.bannerSubMenuListItem}><a href={'/about-us'}>About Us</a></li>));
            bannerListItems.push((<li className={styles.bannerSubMenuListItem}><a href={'/contact-us'}>Contact Us</a></li>));
        }
        return bannerListItems;
    }


    return (
        <div className={styles.banner}>
            <div className={styles.bannerLogo}>
                <a href={'/'} style={{'text-decoration':'none', color: 'black'}}>
                    <h3 style={{color: 'var(--color-15)'}}>Stat Spyder</h3>
                </a>
            </div>
            <div className={styles.bannerButtonGroup}>
                <button onClick={toggleSubMenu} className={styles.bannerMenuBtn}> MENU </button>
                {/*<button onClick={toggleSubMenu}>*/}
                {/*    <img src={}/>*/}
                {/*</button>*/}
                {
                    isSubMenuOpen && (
                        <div className={styles.bannerSubMenu}>
                        <ul className={styles.bannerSubMenuLi}>
                            {displayBannerListItems()}
                        </ul>
                        </div>
                    )
                }
            </div>
        </div>
    )
}