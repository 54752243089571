import ApiService from "./apiService";
import StateService from "./stateService";
import {StateConfig} from "../config/config";
import WidgetModel from "../model/widgetModel";
import {assign, filter, find, includes, map} from "lodash";

export default class WorksheetService {
    static _instance;
    constructor() {
        this.worksheets = StateService.instance().getSharedState().worksheets;
        this.apiService = ApiService.instance();

        StateService.instance().registerListener(StateConfig.WORKSHEETS, (newState) => {
            this.worksheets = newState.worksheets;
        });
    }

    static instance() {
        if(this._instance) return this._instance;

        this._instance = new WorksheetService();
        return this._instance;
    }

    createWorksheet(worksheetName, widgets) {
        if(Object.keys(this.worksheets).indexOf(worksheetName) === -1) {
            const widgetModels = widgets.map(x => { return new WidgetModel(x.widgetType, x.widgetName) });
            const worksheet = {name: worksheetName, widgets: widgetModels};
            this.worksheets.push(worksheet);
            const userId = StateService.instance().getSharedState().userId;
            ApiService.instance().updateUserWorksheets(userId, this.worksheets).then(result => {
                if(result.data.isSuccess) {
                    StateService.instance().updateState(StateConfig.WORKSHEETS, this.worksheets);
                } else {
                    alert('Failed to save worksheet!');
                }
            }).catch(err => {
                alert('Failed to save worksheet!');
            });
        } else {
            console.log('worksheet name already exists...')
        }
    }

    updateWorksheet(updatedWorksheet, widgetTypes) {
        const updatedWorksheets = map(this.worksheets, (worksheet) => {
            if(worksheet.name === updatedWorksheet.name) {
                const newWidgets = map(filter(widgetTypes, (widgetType) => {
                    return !includes(map(updatedWorksheet.widgets, (widget) => {return widget.widgetType}), widgetType);
                }), (x)=> { return new WidgetModel(x)});
                updatedWorksheet.widgets = [...updatedWorksheet.widgets, ...newWidgets];
                console.log("WorksheetService::updateWorksheet -> worksheet updated");
                return updatedWorksheet
            }
            return worksheet;
        })
        StateService.instance().updateState(StateConfig.WORKSHEETS, updatedWorksheets);
        StateService.instance().updateState(StateConfig.ACTIVE_WORKSHEET, updatedWorksheet);
    }

    getWorksheetWidgets(worksheetName) {
        return this.worksheets[worksheetName].widgets || [];
    }

    deleteWorksheet(worksheetName) {
        this.worksheets = this.worksheets.filter(x => x.name !== worksheetName);
        StateService.instance().updateState(StateConfig.WORKSHEETS, this.worksheets)
        //todo: create an api call to delete worksheet
    }

    addWidget(worksheetName, widget) {
        this.worksheets.widgets.push(widget)
    }
    removeWidget(worksheetName, widget) {
        this.this.worksheets[worksheetName].widgets =  this.worksheets[worksheetName].widgets.filter(x => x.name !== widget.name );
        //todo: api call to save worksheet
    }

    updateWidget(worksheetName, widget) {
        this.this.worksheets[worksheetName].widgets = this.worksheets[worksheetName].widgets.filter(x => x.name !== widget.name );
        //todo: api call to save worksheet
    }
}