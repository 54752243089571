class WidgetModelBase {
    name;
    sport;
    type;
    widgetType;
    id;
    hiddenColumns;
    filters;
    isLocked;
    constructor(widgetType) {
        this.widgetType = widgetType
        this.isLocked = false;
        this.name = '';
        this.sport = '';
        this.id = '';
        this.hiddenColumns = [];
        this.filters = {};
    }
}

export default WidgetModelBase;