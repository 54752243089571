import React, { useEffect, useState } from 'react';
import styles from './bettingBoard.module.css';
const BettingBoard = () => {
    const fakeData = [
        { game: "Event 1", over: 150, under: -135 },
        { game: "Event 2", over: 150, under: -135 },
        { game: "Event 3", over: 150, under: -135 },
        { game: "Event 4", over: 150, under: -135 },
        { game: "Event 5", over: 150, under: -135 },
        { game: "Event 6", over: 150, under: -135 },
        { game: "Event 7", over: 150, under: -135 },
        { game: "Event 8", over: 150, under: -135 },
        ];

    const [rows, setRows] = useState(fakeData);
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateOdds();
            // scrollBoard();
        }, 2000);

        return () => clearInterval(intervalId);
    }, [rows]);

    const updateOdds = () => {
        const updatedRows = rows.map((data) => {
            const newOver = data.over + (Math.random() > 0.5 ? 1 : -1) * Math.floor(Math.random() * 3);
            const newUnder = data.under + (Math.random() > 0.5 ? 1 : -1) * Math.floor(Math.random() * 3);

            const updatedData = { ...data, over: newOver, under: newUnder };
            return updatedData;
        });
        setRows(shuffleOdds(updatedRows));
    };

    const shuffleOdds = (array) =>  {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap elements
          }
      return array;
    }

    const scrollBoard = () => {
        setScrollTop((prevScrollTop) => {
            const newScrollTop = prevScrollTop + 40;
            return newScrollTop >= (rows.length / 2) * 40 ? 0 : newScrollTop;
        });
    };

    return (
        <div className={styles.bettingBoard}>
            <div className={styles.boardHeader}>
                <span>Game</span>
                <span>Over Odds</span>
                <span>Under Odds</span>
            </div>
            <div className={styles.boardContent} style={{ transform: `translateY(-${scrollTop}px)` }}>
                {rows.map((data, index) => (
                    <div
                        className={`${styles.row}`}
                        key={index}
                    >
                        <span className={"game"}>{data.game}</span>
                        <span className={`over ${data.over > fakeData[index].over ? `${styles.highlightRed}` : `${styles.highlightGreen}`}`}>
                            {data.over}
                        </span>
                        <span className={`under ${data.under > fakeData[index].under ? `${styles.highlightRed}` : `${styles.highlightGreen}`}`}>
                            {data.under}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BettingBoard;
