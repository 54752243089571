import {isNull} from "lodash";

class TickerModel {
    constructor(sportType, team1, team2, scoreTeam1, scoreTeam2, eventId) {
        this.team2 = team2;
        this.sportType = sportType;
        this.team1 = team1;
        this.eventId = eventId;
        this.scoreTeam1 = scoreTeam1;
        this.scoreTeam2 = scoreTeam2;
        this.isCompleted = false;
    }

    static fromResult(apiData) {
        if(isNull(apiData['scores'])) return null;

        const ticker = new TickerModel();
        ticker.eventId = apiData['id'];
        ticker.sportType = apiData['sport_title'];
        ticker.team1 = apiData['home_team'];
        ticker.team2 = apiData['away_team']
        const t1 = apiData['scores'].find(x => x['name'] == ticker.team1);
        const t2 = apiData['scores'].find(x => x['name'] == ticker.team2);
        ticker.scoreTeam1 = t1.score;
        ticker.scoreTeam2 = t2.score;
        ticker.isCompleted = apiData['completed'];

        return ticker;
    }
}

export default TickerModel;