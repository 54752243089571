import {InitialListenerData, InititalStateData, ListenerConfig, StateConfig} from "../config/config";
import {isFunction, isNull, isUndefined} from "lodash";
export default class StateService {
    static _instance;
    sharedState;
    constructor() {
        //todo: load some data from session storage
        const stateData = sessionStorage.getItem(StateConfig.STATE_SESSION);
        if(!isNull(stateData) && !isUndefined(stateData)) this.sharedState = JSON.parse(stateData);
        else this.sharedState = InititalStateData;

        this.listeners = InitialListenerData;
    }

    static instance() {
        if(this._instance) return this._instance;

        this._instance = new StateService();
        return this._instance;
    }

    rehydrate(keys) {
        const rehydratedData = keys.reduce((obj, key) => {
            if(this.sharedState.hasOwnProperty(key))
                obj[key] = this.sharedState[key];
            return obj;
        }, {});
        return rehydratedData;
    }

    clearState() {
        this.sharedState = InititalStateData;
        this.updateState();
        this.listeners = InitialListenerData;
    }
    getStateValue(key) {
        return this.sharedState[key];
    }
    getSharedState() {
        return this.sharedState;
    }
    updateState(key, value) {
        if((isNull(key) || isUndefined(key)) && (isNull(value) || isUndefined(value))) {
            const keys = Object.keys(this.sharedState);
            sessionStorage.setItem(StateConfig.STATE_SESSION, JSON.stringify(this.sharedState));
            keys.forEach(key => {
                this.notifyListeners(key);
            });
        } else {
            this.sharedState[key] = value;
            sessionStorage.setItem(StateConfig.STATE_SESSION, JSON.stringify(this.sharedState));
            this.notifyListeners(key);
        }

    }

    updateActiveWidget() {

    }

    registerListeners(listeners) {
        listeners.forEach((key, lister) => {
           this.registerListener(key, lister)
        });
    }

    registerListener(listenerKey, listener) {
        if(Object.keys(this.listeners).indexOf(listenerKey) > -1 && isFunction(listener)) {
            console.log('StateService::registerListener -> adding listener for: ', listenerKey);
            this.listeners[listenerKey].push(listener)
        }

    }

    notifyListeners(listenerKey) {
        if(!!this.listeners[listenerKey] == false) {
            console.log(`no listeneres for key: ${listenerKey}`); //Todo: create a listener for sessionId
            return;
        }
        if(this.listeners[listenerKey].length > 0) {
            this.listeners[listenerKey].forEach((listener) => {
                listener(this.sharedState);
            })
        }
    }
}