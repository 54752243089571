import React, {useEffect, useState} from "react";
import {CompactTable} from "@table-library/react-table-library/compact";
import ApiService from "../../service/apiService";
import {SportsConfig, StateConfig, WidgetConfig} from "../../config/config";
import {TableUtil} from "../../util/tableUtil";
import {useTheme} from "@table-library/react-table-library/theme";
import {getTheme} from "@table-library/react-table-library/baseline";
import StateService from "../../service/stateService";

export default function Widget(props) {
    const apiService = ApiService.instance();
    const { isModify, config, cbRemoveWidget} = props;
    const [selectedSport, setSelectedSport] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [typeValues, setTypeValues] = useState([]);
    const [playerId, setPlayerId] = useState('');
    const [widgetConfig, setWidgetConfig] = useState(props.config);
    const [name, setName] = useState(props.config.name);
    const [widgetType, setWidgetType] = useState(props.config.widgetType);
    const [sport, setSport] = useState(props.config.sport);
    const [type, setType] = useState(props.config.type);
    const [teamOptions, setTeamOptions] = useState([]);
    const [year, setYear] = useState((new Date()).getFullYear());
    const [hiddenColumns, setHiddenColumns] = useState(props.config.hiddenColumns);
    const [isLocked, setIsLocked] = useState(false);
    const [playerList, setPlayerList] = useState([]);
    const [playerSelected, setPlayerSelected] = useState(null)
    const [currentPlayerData, setCurrentPlayerData] = useState(null);
    const [tableData, setTableData] = useState({nodes: []});
    const [tableColumns, setTableColumns] = useState([]);

    const theme = useTheme(getTheme());

    const getWidgetTitle = () => {
        switch (widgetType) {
            case WidgetConfig.widgetTypes.widgetTeamTrends:
                return 'Team Trends';
            case WidgetConfig.widgetTypes.widgetPlayerTrends:
                return 'Player Trends';
        }
    }
    useEffect(() => {
        //api call
        // loadStats();
        //setTableData({nodes: });
    })

    const loadStats = ()  => {
        let request = {}
        let statResponseData = '';
        request['sport'] = selectedSport;
        request['year'] = year;
        if(widgetType === WidgetConfig.widgetTypes.widgetTeamTrends) {

            ApiService.instance().getStats(request)
                .then(response => {
                    if(response.data.isSuccess) {
                        //store the data
                    }
                })
                .catch(err => {
                    alert(err);
                });

        } else {
            request['playerId'] = selectedSport;
            ApiService.instance().getPlayers(request)
                .then(response => {
                    if(response.data.isSuccess) {
                        //store the data
                        setPlayerList(response.data.stats)
                    }
                })
                .catch(err => {
                    alert(err);
                });
        }
    }

    const onSportChange = (e) => {
        console.log('widget::onSportChange -> sport: ', e.target.value);
        setSport(e.target.value);
        setWidgetConfig({'sport': e.target.value});
        setSelectedSport(e.target.value);
        const typeValues = sport === '' ? [] : Object.keys(SportsConfig[e.target.value]);
        setTypeValues(typeValues);
        //Todo: Clean this up
        if(['nfl', 'nba', 'nhl'].indexOf(e.target.value) > -1) {
            const teamsRequest = {
                'year': year,
                'team': '',
                'sport': e.target.value
            }
            ApiService.instance().getTeams(teamsRequest).then(response => {
                if(response.data.isSuccess)
                    setTeamOptions(response.data.teams);
            });
        }
    }
    const onTypeChange = (e) => {
        setType(e.target.value);
        setSelectedType(e.target.value);
        setWidgetConfig({'type': e.target.value});
        const tableColumns = SportsConfig[sport][e.target.value].columns;
        console.log('widget::onTypeChange -> tableColumns: ', tableColumns);
        setTableColumns(tableColumns);
    }

    const displaySportOptions = () => {
        const sportNames = Object.keys(SportsConfig);
        const options = sportNames.map(x => {return (<option key={x} value={x}> {x.toUpperCase()} </option>)});
        return (
            <select value={selectedSport} disabled={isLocked} onChange={onSportChange}>
                {options}
            </select>
        )
    }

    const onLock = () => {
        setIsLocked(!isLocked)
        const {worksheets, activeWorksheet }  = StateService.instance().getSharedState();
        activeWorksheet.widgets.forEach(x => {
            if(x.widgetType === widgetType) {
                x.name = name;
                x.sport = sport;
                x.type = type;
                x.hiddenColumns = hiddenColumns;
                x.isLocked = !isLocked;
            }
        });
        worksheets.forEach(x => {
            if(x.name === activeWorksheet.name) {
                x.widgets.forEach(y => {
                    if(y.widgetType === widgetType)
                        y = activeWorksheet;
                })
            }
        })
        StateService.instance().updateState(StateConfig.ACTIVE_WORKSHEET, activeWorksheet);
        StateService.instance().updateState(StateConfig.WORKSHEETS, worksheets);
    }
    const displayTypeValues = () => {
        console.log('widget::displayTypeValues: ', typeValues);
        const options = typeValues.map(x => {return (<option key={x} value={x}> {x.toUpperCase()} </option>)});
        return (
            <select value={selectedType} disabled={isLocked} onChange={onTypeChange}>
                {/*<option value={''}>Select Type</option>*/}
                {options}
            </select>
            )

    }

    const displayWidgetInfo = () => {
        if(!playerSelected)
            return showRosterList();
        else
            return showPlayerData()
    }

    const showPlayerData = () => {
        const clearPlayerData = () => {
            setPlayerSelected(null);
            setCurrentPlayerData(null);
        }
        return (
            <div>
                <div>
                    <div>Player Data</div>
                    <div>
                        <button onClick={() => clearPlayerData()}>Back</button>
                    </div>
                </div>
                <div>
                    {!!currentPlayerData && currentPlayerData}
                </div>
            </div>
        );

    }
    const showRosterList = () => {
        const getPlayerData = (playerId) => {
            setPlayerSelected(playerId);
            const request = {
                'sport': sport,
                'playerId': playerId,
                'year': year
            }

            ApiService.instance().getStats(request).then(response => {
                if(response.data.isSuccess && !!response.data.stats) {
                    setCurrentPlayerData(response.data.stats.gameLog);
                }
            }).catch(err => console.log('widget::getPlayerData -> err: ', err));

        }
        const players = [];
        for(let i = 0; i < playerList.length; i++) {
            switch (selectedSport) {
                case 'nba':
                    const player = playerList[i];
                    players.push(
                        <div className={'player'}
                            key={player.playerId}
                            onClick={() => {getPlayerData(player.playerId)}}>
                            <div>{player.playerName}</div>
                            <div>{player.teamName}</div>
                            <div>{player.teamName}</div>
                        </div>)
                    ;
                    break;
                default:
                    break;
            }
        }

        return players;
    }

    return (
        <div className={isModify ? 'widget modify-widget' : 'widget'} id={''}>
            <div className={'widget-header'}>
                <div className={'widget-title'}>
                    <h5>{getWidgetTitle()}</h5>
                </div>
                <div className={'widget-select-group'}>
                    <div className={'widget-select-item'}>
                        <h5>Sport:</h5>
                        {displaySportOptions()}
                    </div>
                    <div className={'widget-select-item'}>
                        <h5>Type:</h5>
                        {typeValues && displayTypeValues()}
                    </div>
                    <div>
                        <h5>Year</h5>
                        <select>
                            {[2024, 2023, 2022].map(x => {return (<option onClick={(e) => {setYear(e.target.value)}}>{x}</option>)})}
                        </select>
                    </div>
                    {widgetType === WidgetConfig.widgetTypes.widgetTeamTrends && (
                        <div className={'widget-select-group'}>
                            <h5>Team</h5>
                            <select>
                                {teamOptions.map( team => {return (<option>{team.teamFullName}</option>)})}
                            </select>
                        </div>
                    )}
                    <div className={'widget-select-item'}>
                        <button onClick={() => onLock()}>{isLocked ? 'Unlock' : 'Lock'}</button>
                        {isModify && <button onClick={() => cbRemoveWidget(props.config.widgetType)}>Delete</button>}
                        <button onClick={() => loadStats()}>Refresh</button>
                    </div>
                </div>
            </div>
            <div className={'widget-body'}>
                {displayWidgetInfo()}
                {/*<CompactTable columns={TableUtil.createTableColumns(tableColumns)} data={tableData} theme={theme}></CompactTable>*/}
            </div>
            <div className={'widget-footer'}></div>
        </div>
    )
}