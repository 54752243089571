import WidgetModelBase from "./widgetModelBase";

export default class WidgetModel extends WidgetModelBase {
    constructor(widgetType, widgetName) {
        super(widgetType);
        this.name = widgetName;
    }

    setId(id) {
        this.id = id;
    }
    setName(name) {
        this.name = name;
    }
    setSport(sport) {
        this.sport = sport;
    }
    setType(type) {
        this.type = type;
    }
    setHideColumn(columnName){
        this.hiddenColumns.push(columnName);
    }

    setFilters(filters) {
        this.filters = filters;
    }

    setIsLocked(isLocked) {
        this.isLocked = isLocked;
    }


}