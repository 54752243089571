export const SportsConfig =  {
    nba: {
        team: {
            columns: ['Rk', 'Team', 'G', 'MP', 'FG', 'FGA', 'FG%', '3P', '3PA', '3P%', '2P', '2PA', '2P%', 'FT', 'FTA', 'FT%', 'ORB', 'DRB', 'TRB', 'AST', 'STL', 'BLK', 'TOV', 'PF', 'PTS'],
            filters: [],
        },
        player: {
            columns: ['Rk', 'Team', 'G', 'MP', 'FG', 'FGA', 'FG%', '3P', '3PA', '3P%', '2P', '2PA', '2P%', 'FT', 'FTA', 'FT%', 'ORB', 'DRB', 'TRB', 'AST', 'STL', 'BLK', 'TOV', 'PF', 'PTS'],
            filters: [],
        },
    },
    nhl: {
        team: {
            columns: [],
            filters: [],
        },
        player: {
            columns: [],
            filters: [],
        },
    },
    nfl: {
        team: {
            columns: [],
            filters: [],
        },
        player: {
            columns: [],
            filters: [],
        },
    },
    mls: {
        team: {
            columns: [],
            filters: [],
        },
        player: {
            columns: [],
            filters: [],
        },
    },
    prem: {
        team: {
            columns: [],
            filters: [],
        },
        player: {
            columns: [],
            filters: [],
        },
    },
    ufc: {
        player: {
            columns: [],
            filters: [],
        },
    },
    ncaaf: {
        player: {
            columns: [],
            filters: [],
        },
    }
}

export const RouterConfig = {}

export const WidgetConfig = {
    widgetTypes: {
        widgetTeamTrends: 'widgetTeamTrends',
        widgetPlayerTrends: 'widgetPlayerTrends',
        widgetNewsFeed: 'widgetNewsFeed',
        widgetTicker: 'widgetTicker',
        widgetNotifications: 'widgetNotifications',
        widgetOdds: 'widgetOdds'
    },
    widgets: {
        widgetTeamTrends : {
            sport:{
                nba: SportsConfig.nba.team,
                nfl: SportsConfig.nba.team,
                ncaaf: SportsConfig.nba.team,
                mls: SportsConfig.nba.team,
                prem: SportsConfig.nba.team,
                nhl: SportsConfig.nba.team,
            },
        },
        widgetPlayerTrends : {
            nba: SportsConfig.nba.player,
            nfl: SportsConfig.nfl.player,
            ncaaf: SportsConfig.ncaaf.player,
            mls: SportsConfig.mls.player,
            prem: SportsConfig.prem.player,
            nhl: SportsConfig.nhl.player,
            ufc: SportsConfig.ufc.player
        },
        widgetNewsFeed : {
            sportTypes:[],
            columns:[],
            keywords:[],
            filters:[]
        },
        widgetTicker : {
            sportTypes:[],
            columns:[],
            filters:[]
        },
        widgetNotifications : {
            sportTypes:[],
            columns:[],
            filters:[]
        },
        widgetOdds : {

            sportTypes:[],
            columns:[],
            filters:[]
        },
    }
}

export const StateConfig =  {
    USER_ID: 'userId',
    IS_LOGGED_IN: 'isLoggedIn',
    LOGIN_ID: 'loginId',
    SESSION_ID: 'sessionId',
    IS_MODAL_OPEN: 'isModalOpen',
    ACTIVE_WORKSHEET: 'activeWorksheet',
    WORKSHEETS: 'worksheets',
    STATE_SESSION: 'stateSession'
}

export const InititalStateData = {
    isModalOpen: false,
    activeWorksheet: null,
    activeWorksheetWidgets: [],
    worksheets: [],
    userId:'',
    sessionId:''
}

export const BetConfig = {
    marketType: {
        h2h: 'h2h',
        spreads: 'spreads',
        prop:'prop'
    }
}

export const InitialListenerData = {
    userId: [],
    isLoggedIn: [],
    isModalOpen: [],
    activeWorksheet: [],
    activeWorksheetWidgets: [],
    worksheets: []
}

export const ApiRoutesConfig = {

}

// {QB: 'QB', RB: 'RB', WR: 'WR', TE: 'TE', DEF: 'DEF', KICK: 'KICK'};