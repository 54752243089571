import BettingBoard from "../bettingBoard/bettingBoard";
import styles from './underConstruction.css'
const UnderConstruction = () => {

    return (
        <div className={styles.underConstruction}>
            <div style={{'color': 'white'}}>STATSPYDER COMING SOON!</div>
            <BettingBoard />
        </div>
    )
}

export default UnderConstruction