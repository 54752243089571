import logo from './logo.svg';
import './App.css';
import Banner from "./component/banner/banner";
import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import Home from "./component/home/home";
import Landing from "./component/landing";
import Footer from "./component/footer/footer";
import Signup from "./component/signup";
import Login from "./component/login";
import Register from "./component/register";
import UserSettings from "./component/userSettings";
import WidgetDemo from "./component/widgetDemo";
import UnderConstruction from "./component/underConstruction/underConstruction";
import {useEffect} from "react";

function App() {
    useEffect(() => {
        console.log('value', process.env.REACT_APP_IS_UNDER_CONSTRUCTION)
    }, []);
  return (
    <div className="App">
        <Banner/>
        <div className={'container-full'} id={'app-view'}>
            <div className={'container'}>
                {process.env.REACT_APP_IS_UNDER_CONSTRUCTION === 'true'
                    ? (<UnderConstruction />)
                    : (
                        <Router>
                    <Routes>
                        <Route
                            path={'/'}
                            element={<Home />}>
                        </Route>
                        <Route
                            path={'/home'}
                            element={<Home />}>
                        </Route>
                        <Route
                            path={'/register'}
                            element={<Register />}>
                        </Route>
                        <Route
                            path={'/login'}
                            element={<Login />}>
                        </Route>
                        <Route
                            path={'/account'}
                            element={<UserSettings />}>
                        </Route>
                        <Route
                            path={'/test/widget'}
                            element={<WidgetDemo />}
                        >
                        </Route>
                        {/*<Route*/}
                        {/*    path={'/about-us'}*/}
                        {/*    element={<Login />}>*/}
                        {/*</Route>*/}
                        {/*<Route*/}
                        {/*    path={'/contact-us'}*/}
                        {/*    element={<Login />}>*/}
                        {/*</Route>*/}
                        {/*<Route*/}
                        {/*    path={'/account'}*/}
                        {/*    element={<Home />}>*/}
                        {/*</Route>*/}
                    </Routes>
                </Router>

                    )
                }

            </div>
            {/*<FooterMenu />*/}
        </div>
        <Footer />
    </div>
  );
}

export default App;
